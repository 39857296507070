import PropTypes from 'prop-types';
import React from 'react';
import useWindowSize from '../../../hooks/useWindowSize';
import ExploreMoreWaysSaveBackground from '../../../images/explore-more-ways-save-background.webp';
import ExploreMoreWaysSaveTabletBackground from '../../../images/explore-more-ways-save-background-tablet.webp';
import ExploreMoreWaysSaveMobileBackground from '../../../images/explore-more-ways-save-background-mobile.webp';
import ExploreWaysToSaveBannerStyles from './ExploreWaysToSaveBanner.styled';

export const ExploreWaysToSaveBanner = ({ headline, backgroundColor }) => {
  const [width, height] = useWindowSize();
  const isTablet = width <= 1099;
  const isMobile = width <= 767;

  const backgroundImage = () => {
    if (isMobile) {
      return ExploreMoreWaysSaveMobileBackground;
    }
    if (isTablet) {
      return ExploreMoreWaysSaveTabletBackground;
    }

    return ExploreMoreWaysSaveBackground;
  };

  return (
    <ExploreWaysToSaveBannerStyles backgroundColor={backgroundColor}>
      <img src={backgroundImage()} alt="Explore More Ways to Save" />
      <h2>{headline}</h2>
    </ExploreWaysToSaveBannerStyles>
  );
};

ExploreWaysToSaveBanner.props = {
  headline: PropTypes.string,
  backgroundColor: PropTypes.string,
};

ExploreWaysToSaveBanner.defaultProps = {
  backgroundColor: 'blue',
};

export default ExploreWaysToSaveBanner;
