import React from 'react';
import styled from 'styled-components';
import Colors from '../../colors';

export const FormContainer = styled.div`
  background: ${Colors.primary.white.standard};
  box-shadow: 0 5px 21px 0 rgba(109, 110, 113, 0.13);
  width: 100%;
  margin: auto;
  position: relative;

  @media screen and (min-width: 1025px) {
    max-width: none;
    width: 50%;
    margin: unset;
    padding: 0 0 10px;
  }

  .form-container {
    z-index: 9;
    position: relative;
    @media screen and (min-width: 1025px) {
      margin: 77px 0 0;
    }
    @media screen and (min-width: 2000px) {
      margin: 25% 0;
    }
  }

  .form-headline {
    box-sizing: border-box;
    max-width: 512px;
    margin: 0 auto;
    padding: 0;
    width: 88%;
    text-align: center;

    @media screen and (min-width: 768px) {
      width: 66.7%;
      padding: 61px 0 24px;
    }

    @media screen and (min-width: 1025px) {
      padding: 0 0 16px;
      width: 69%;
      max-width: 496px;
    }

    svg {
      margin: 0 auto;
      width: 271px;

      @media screen and (min-width: 768px) {
        width: 327px;
      }

      @media screen and (min-width: 1025px) {
        width: 100%;
      }
    }

    .divider {
      border-top: 1px solid ${Colors.primary.black.standard};
      width: 32px;
    }

    .form-text {
      font: 700 17px/22px 'acumin-pro', sans-serif;
      color: ${Colors.accent.darkGrey2.standard};
      margin-block-start: 0;
      margin-block-end: 0;
      margin: 0 auto;
      letter-spacing: -0.3px;

      @media screen and (min-width: 768px) {
        font-size: 22px;
        line-height: 26px;
        margin-top: 30px;
      }

      br {
        display: none;
        @media screen and (min-width: 1025px) {
          display: block;
        }
      }
    }
  }

  .bold {
    font-weight: bold;
  }

  .group {
    @media only screen and (min-width: 768px) {
      display: flex;
      justify-content: space-between;
    }
  }

  form {
    margin: 0 auto;
    padding: 0;
    width: 88%;
    padding-bottom: 60px;

    @media only screen and (min-width: 768px) {
      width: 66.7%;
      max-width: 512px;
      padding-bottom: 61px;
    }

    @media only screen and (min-width: 1025px) {
      width: 69%;
      padding-bottom: 32px;
      max-width: 496px;
    }
  }

  .input-container {
    position: relative;

    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      width: 100%;

      input {
        max-width: none;
      }
    }
  }

  .half-width-input {
    @media only screen and (min-width: 768px) {
      width: 47%;
    }
  }

  .full-width-input {
    width: 100%;
  }

  input {
    border-bottom: 2px solid ${Colors.accent.redTagRed.standard} !important;
  }

  .error-message {
    color: ${Colors.accent.ladyBug.standard};
    font-size: 12px;
    margin-top: 2px;
  }

  .no-margin {
    margin: 0;
  }

  .submit-btn {
    width: 100%;
    background-color: ${Colors.accent.redTagRed.standard} !important;
    color: ${Colors.primary.white.standard};
    border: none;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
      background-color: ${Colors.accent.redTagRed.standard} !important;
    }
  }

  .agree-terms {
    font: 400 12px/17px 'source-sans-pro', sans-serif;
    color: ${Colors.primary.black.standard};
    width: 100%;
    margin: 9px 0 16px;

    span,
    a {
      font: 400 12px/17px 'source-sans-pro', sans-serif;
      color: ${Colors.primary.black.standard};
    }

    .capitalize-first-letter {
      text-transform: capitalize;
    }

    a {
      text-decoration: underline;
      color: ${Colors.primary.black.standard};
      &:hover {
        cursor: pointer;
        font-weight: 400;
      }
    }
  }
`;
