import PropTypes from 'prop-types';
import Button from 'pubweb-smokey/dist/components/Buttons/Button';
import React, { useContext, useState } from 'react';
import { contactFormSubmit } from '../../../Api';
import { getUTMParametersFromSessionCache } from '../../../components/Shared/SmokeyTransitional/utils/utils';
import { DXAccountContext } from '../../../contexts/DXAccountContext';
import Loader from '../../../images/svg/loader';
import { fireSegmentEvent, formatPhoneNumber } from '../../../utils';
import { getGAClientId } from '../../../utils/config';
import {
  fireMNTNConversionPixel,
  scrollIntoViewHelper,
} from '../../../utils/utils';
import TextField from '../../Shared/SmokeyTransitional/components/TextField/TextField';
import ContactModal from '../ContactModal/ContactModal';
import ContactModalThankYou from '../ContactModalThankYou/ContactModalThankYou';
import RequestInfoModalFormStyles from './RequestInfoModalForm.styled';

export const RequestInfoModalForm = ({
  title,
  closeFunction,
  ty,
  dealer,
  home,
  isPortalForm,
  updatedUser,
  isHomeDetails,
  isCuratedCoolection,
  retailEventLabel,
  originWebFormCategory,
  originWebPageType,
}) => {
  const dxContext = useContext(DXAccountContext);

  const [firstName, setFirstName] = useState({
    value: updatedUser?.firstName || '',
    error: null,
  });
  const [lastName, setLastName] = useState({
    value: updatedUser?.lastName || '',
    error: null,
  });
  const [phone, setPhone] = useState({
    value: updatedUser?.phone || '',
    error: null,
  });
  const [email, setEmail] = useState({
    value: updatedUser?.email || '',
    error: null,
  });
  const [showThankYou, setShowThankYou] = useState(ty || false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  let utmParams;
  try {
    utmParams = getUTMParametersFromSessionCache();
  } catch (error) {
    utmParams = {};
  }

  const orignWebPageTypeValue = () => {
    if (
      (dealer?.websiteTemplateDescription === 'BuilderModel') &
      isHomeDetails
    ) {
      return 'retail-builder-home-details';
    }
    if (isCuratedCoolection) {
      return originWebPageType;
    }
    return 'retail-home-details';
  };

  const firstnameIsValid = () => {
    let isValid = true;

    if (!firstName.value || firstName.value.length < 2) {
      setFirstName({ ...firstName, error: '*Please enter your first name.' });
      isValid = false;
    } else if (!firstName.value.match(/^[a-zA-Z ,.'-]+$/i)) {
      setFirstName({ ...firstName, error: '*Please enter your first name.' });
      isValid = false;
    } else {
      setFirstName({ ...firstName, error: null });
    }

    return isValid;
  };

  const lastnameIsValid = () => {
    let isValid = true;

    if (!lastName.value || lastName.value.length < 2) {
      setLastName({ ...lastName, error: '*Please enter your last name.' });
      isValid = false;
    } else if (!lastName.value.match(/^[a-zA-Z ,.'-]+$/i)) {
      setLastName({ ...lastName, error: '*Please enter your last name.' });
      isValid = false;
    } else {
      setLastName({ ...lastName, error: null });
    }

    return isValid;
  };

  const emailIsValid = () => {
    let isValid = true;
    const pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    if (!email.value) {
      isValid = false;
      setEmail({ ...email, error: '*Please enter your email.' });
    } else if (!pattern.test(email.value)) {
      isValid = false;
      setEmail({ ...email, error: '*Please enter a valid email.' });
    } else {
      setEmail({ ...email, error: '' });
    }

    return isValid;
  };

  const phoneIsValid = () => {
    let isValid = true;

    if (!phone.value) {
      isValid = false;
      setPhone({ ...phone, error: '*Please enter your phone number.' });
    } else if (
      !phone.value.match(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/i)
    ) {
      isValid = false;
      setPhone({ ...phone, error: '*Please enter a valid phone number.' });
    } else {
      setPhone({ ...phone, error: '' });
    }

    return isValid;
  };

  const validateForm = () => {
    let formIsValid = true;
    let errorFields = {};

    //all must be true to submit, so bitwise 'and' it is
    formIsValid &= firstnameIsValid() ? true : !(errorFields.firstName = true);
    formIsValid &= lastnameIsValid() ? true : !(errorFields.lastName = true);
    formIsValid &= emailIsValid() ? true : !(errorFields.email = true);
    formIsValid &= phoneIsValid() ? true : !(errorFields.phone = true);

    return [formIsValid, errorFields];
  };

  const formSubmitHandler = async () => {
    const [formIsValid, errorFields] = validateForm();

    if (!formIsValid) {
      scrollIntoViewHelper(errorFields);
      return;
    }

    if (isPortalForm) {
      if (
        (dxContext?.state?.account?.phone &&
          dxContext?.state?.account.phone !== phone) ||
        (dxContext?.state?.account?.firstName &&
          dxContext?.state?.account.firstName !== firstName) ||
        !dxContext?.state?.account?.firstName ||
        (dxContext?.state?.account?.lastName &&
          dxContext?.state?.account.lastName !== lastName) ||
        !dxContext?.state?.account?.lastName
      ) {
        const infoUpdate = {
          ...updatedUser,
          ['phone']: phone?.value,
          ['firstName']: firstName?.value,
          ['lastName']: lastName?.value,
        };
        dxContext?.actions?.updateAccountRetail(infoUpdate);
      }
    }

    const formData = isPortalForm
      ? {
          firstName: firstName.value,
          lastName: lastName.value,
          phoneNumber: phone.value,
          emailAddress: email.value,
          dataExtension: 'FormActivityRetail',
          dealerNumber: dealer.dealerNumber,
          canEmail: true,
          doNotCall: false,
          originWebURL: window?.location?.href,
          originWebApp: 'clayton-retail-website',
          originWebPageType: 'clayton-myhome-account',
          originWebFormType: 'lead-form',
          originWebFormCategory: 'myhome-account-contact-us',
          utmSource: utmParams.utm_source || '',
          utmCampaign: utmParams.utm_campaign || '',
          utmTerm: utmParams.utm_term || '',
          utmContent: utmParams.utm_content || '',
          utmMedium: utmParams.utm_medium || '',
          kenshooId: utmParams.kenshooid || '',
          gaClientId: getGAClientId() || '',
          claytonAccountId: '',
          googleClickId: utmParams.gclid || '',
          bingClickId: utmParams.msclkid || '',
          facebookClickId: utmParams.fbclid || '',
        }
      : {
          firstName: firstName.value,
          lastName: lastName.value,
          phoneNumber: phone.value,
          emailAddress: email.value,
          dataExtension: 'FormActivityRetail',
          dealerNumber: dealer.dealerNumber,
          modelNumber: isCuratedCoolection ? null : home.modelNumber,
          serialNumber: isCuratedCoolection ? null : home.serialNumber,
          canEmail: true,
          doNotCall: false,
          originWebURL: window?.location?.href,
          originWebApp: 'clayton-retail-website',
          originWebPageType: orignWebPageTypeValue(),
          originWebFormType: 'lead-form',
          originWebFormCategory: isCuratedCoolection
            ? originWebFormCategory
            : 'home-details-info',
          utmSource: utmParams.utm_source || '',
          utmCampaign: utmParams.utm_campaign || '',
          utmTerm: utmParams.utm_term || '',
          utmContent: utmParams.utm_content || '',
          utmMedium: utmParams.utm_medium || '',
          kenshooId: utmParams.kenshooid || '',
          gaClientId: getGAClientId() || '',
          claytonAccountId: '',
          googleClickId: utmParams.gclid || '',
          bingClickId: utmParams.msclkid || '',
          facebookClickId: utmParams.fbclid || '',
        };
    setIsSubmitting(true);
    const formSubmitted = await contactFormSubmit(formData);

    if (formSubmitted.status === 200) {
      setShowThankYou(true);
      setIsSubmitting(false);
      fireMNTNConversionPixel();
      if (dataLayer) {
        dataLayer.push({ event: 'ga-request-info-modal-form-submit' });
      }
      fireSegmentEvent('Generic Contact Form Submitted', {
        formId: 'request-info-form',
        email: formData?.emailAddress || '',
        firstName: formData?.firstName || '',
        lastName: formData?.lastName || '',
        phone: formData?.phoneNumber || '',
        comments: '',
        eventContext: retailEventLabel ? retailEventLabel : '',
      });
    }
  };

  if (showThankYou) {
    return (
      <ContactModalThankYou
        label={
          title == 'Get More Information' ? null : 'Requesting Information'
        }
        title={title}
        closeFunction={closeFunction}
      />
    );
  }

  return (
    <RequestInfoModalFormStyles>
      <ContactModal>
        {title !== 'Get More Information' && (
          <div className="label">Requesting Information</div>
        )}
        {title && <div className="h3">{title}</div>}
        <form>
          <TextField
            id="firstName"
            className="half"
            textFieldStyle="primary"
            label="First name"
            value={firstName.value}
            errorMessage={firstName.error}
            onChange={(event) => {
              setFirstName({ value: event.target.value, error: null });
            }}
            onBlur={firstnameIsValid}
            maxLength="40"
          />
          <TextField
            id="lastName"
            className="half"
            textFieldStyle="primary"
            label="Last name"
            value={lastName.value}
            errorMessage={lastName.error}
            onChange={(event) => {
              setLastName({ value: event.target.value, error: null });
            }}
            onBlur={lastnameIsValid}
            maxLength="50"
          />
          <TextField
            id="phone"
            textFieldStyle="primary"
            label="Phone number"
            value={formatPhoneNumber(phone.value)}
            errorMessage={phone.error}
            onChange={(event) => {
              setPhone({ value: event.target.value, error: null });
            }}
            onBlur={phoneIsValid}
            type="tel"
            minLength="10"
            maxLength="10"
          />
          <TextField
            id="email"
            textFieldStyle="primary"
            label="Email"
            value={email.value}
            errorMessage={email.error}
            onChange={(event) => {
              setEmail({ value: event.target.value, error: null });
            }}
            onBlur={emailIsValid}
            maxLength="80"
          />
        </form>
        <Button
          text={isSubmitting ? <Loader /> : 'Request Info'}
          onClick={isSubmitting ? () => {} : formSubmitHandler}
          disabled={isSubmitting}
          automationId="reqInfoModalBtn"
        />
        <p className="disclaimer">
          By submitting this form, I agree that Clayton may contact me by
          telephone, provide me with marketing communications about Clayton
          products and services, and share my contact information as described
          in our website terms.
        </p>
      </ContactModal>
    </RequestInfoModalFormStyles>
  );
};

RequestInfoModalForm.propTypes = {
  title: PropTypes.string,
};

export default RequestInfoModalForm;
