import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, Redirect } from 'react-router-dom';
import App from '../../App';
import ArrowLeft from '../../images/svg/icons/ArrowLeft';
import * as dealerService from '../../services/dealerService';
import * as homesService from '../../services/homesService';
import { fireSegmentEvent, parseInitialPropsContext } from '../../utils';
import LargeCTA from '../Shared/LargeCTA/LargeCTA';
import RecentlyViewedHomes from '../Shared/RecentlyViewedHomes/RecentlyViewedHomes';
import { formatDealerName } from '../Shared/SmokeyTransitional/utils/formatters';
import SaleHomeTile from './SaleHomeTile/SaleHomeTile';
import CuratedCollectionsStyles from './CuratedCollections.styled';
import { findMetaDescription } from '../../utils/utils';
import useWindowSize from '../../hooks/useWindowSize';
import Button from 'pubweb-smokey/dist/components/Buttons/Button';
import Modal from '../Shared/SmokeyTransitional/components/Modal/Modal';
import RequestInfoModalForm from '../Shared/RequestInfoModalForm/RequestInfoModalForm';
import ScheduleVisitModalForm from '../Shared/ScheduleVistModalForm/ScheduleVisitModalForm';
import { getContent } from './CuratedCollectionsContent';

const SaleHomes = ({
  dealer,
  homes,
  homeList,
  isLoading,
  pageContent,
  moveInReadyHomes,
  homesInStock,
  homesOnSale,
  budgetHomes,
  pathName,
}) => {
  const [width, height] = useWindowSize();
  const isMobile = width <= 1099;

  const [showRequestModal, setShowRequestModal] = useState(false);
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const [initialHomes, setInitialHomes] = useState(10);

  let ctaPlacementNumber;
  let visibleHomeCount = homeList?.length;
  if (visibleHomeCount === 0 || visibleHomeCount === 1) {
    ctaPlacementNumber = 0;
  } else if (visibleHomeCount < 6) {
    ctaPlacementNumber = visibleHomeCount - 2;
  } else {
    ctaPlacementNumber = 4;
  }

  const customPageMetaDescription = findMetaDescription(
    dealer?.websiteMetaDescriptions,
    'sale-homes'
  );

  if (
    dealer?.websiteTemplateDescription == 'BuilderModel' &&
    pathName === 'sale-homes'
  ) {
    return <Redirect to="/" />;
  }

  const noHomesText = () => {
    if (pathName === 'sale-homes') {
      return 'sale homes';
    }

    if (pathName === 'mobile-manufactured-homes-on-display') {
      return 'homes on display';
    }

    if (pathName === 'mobile-manufactured-homes-with-land') {
      return 'move-in ready homes';
    }

    if (pathName === 'mobile-manufactured-homes-under-150k') {
      return 'homes under $150K';
    }
    return '';
  };

  const {
    iconSrc,
    iconAlt,
    pageTitle,
    copyText,
    retailEventLabel,
    originWebFormCategory,
    originWebPageType,
    eventContextText,
    titleTag,
  } = pageContent;

  const loadMoreHomes = () => setInitialHomes(initialHomes + 10);

  return (
    <App dealer={dealer} homes={homes} isLoading={isLoading}>
      <CuratedCollectionsStyles>
        <Helmet>
          <title>
            {titleTag} For Sale Near Me | {formatDealerName(dealer?.dealerName)}
          </title>
          <meta
            name="description"
            content={customPageMetaDescription?.metaDescription}
          />
          <meta
            property="og:description"
            content={customPageMetaDescription?.metaDescription}
          />
        </Helmet>
        <span className="blue-box"></span>
        <Link
          to={{ pathname: '/homes', state: { dealer, homes } }}
          className="all-homes"
        >
          <ArrowLeft color="white" /> <span>View All Homes</span>
        </Link>
        <div className="container">
          <div className="intro-content">
            {iconSrc && <img className="icon" src={iconSrc} alt={iconAlt} />}
            {pageTitle && <h1>{pageTitle}</h1>}
            {copyText && <p>{copyText}</p>}
            {homeList?.length > 0 && (
              <div className="request-info">
                {pathName === 'mobile-manufactured-homes-on-display' ? (
                  <Button
                    className="request-info-btn ga-request-info"
                    onClick={() => {
                      setShowScheduleModal(!showScheduleModal);

                      fireSegmentEvent('CTA Clicked', {
                        isNav: false,
                        type: 'button',
                        text: 'SCHEDULE A TOUR',
                        eventContext: eventContextText,
                      });
                    }}
                    automationId="homeDetailsButtonOne"
                  >
                    SCHEDULE A TOUR
                  </Button>
                ) : (
                  <Button
                    className="request-info-btn ga-request-info"
                    onClick={() => {
                      setShowRequestModal(!showRequestModal);

                      fireSegmentEvent('CTA Clicked', {
                        isNav: false,
                        type: 'button',
                        text: 'GET MORE INFORMATION',
                        eventContext: eventContextText,
                      });
                    }}
                    automationId="homeDetailsButtonOne"
                  >
                    GET MORE INFORMATION
                  </Button>
                )}
              </div>
            )}
          </div>
          <div className="sale-home-list">
            {homeList &&
              homeList?.slice(0, initialHomes).map((home, i) => (
                <div
                  key={home.serialNumber ? home.serialNumber : home.modelNumber}
                >
                  <SaleHomeTile
                    key={i}
                    retailHomeObject={home}
                    dealer={dealer}
                    homes={homes}
                  />

                  {i === ctaPlacementNumber &&
                    dealer?.isCorporateAdCampaignParticipant &&
                    dealer?.websiteTemplateDescription !== 'NoSpecialOffers' &&
                    dealer?.websiteTemplateDescription !== 'BuilderModel' &&
                    dealer?.websiteTemplateDescription !==
                      'NoSpecialOffersNoEnergySmart' && (
                      <LargeCTA
                        key="large-cta"
                        buttonText="View Offer"
                        buttonURL="/special-offers/red-tag-sales-event"
                        backgroundColor="red-tag-sale"
                        linkState={(dealer, homes)}
                        smallText={'RED TAG SALE'}
                        largeText={'Lowest prices of the year!'}
                        headlineWidth={'287px'}
                        backgroundImage={
                          !isMobile && {
                            source: require('../../images/promo-images/red-tag-large-cta.jpg'),
                            srcSet: [
                              {
                                source: require('../../images/webp/red-tag-large-cta.webp'),
                              },
                            ],
                            alt: 'Red Tag Sale Banner Image',
                          }
                        }
                        backgroundPosition={'top center'}
                        gaClass="ga-sale-homes-seasonal-campaign"
                        dealer={dealer}
                        homes={homes}
                        logoText={''}
                        retailEventLabel="sale_homes_image_cta_campaign"
                      />
                    )}
                </div>
              ))}
            {!homeList?.length && (
              <div className="no-sale-homes">
                <p>
                  There are no {noHomesText()} at this time. View all{' '}
                  <Link to={{ pathname: '/homes', state: { dealer, homes } }}>
                    available homes
                  </Link>
                  .
                </p>
              </div>
            )}
          </div>

          {pathName !== 'sale-homes' && homeList?.length > 0 && (
            <div className="button-container">
              <Button
                className="center-horizontal button"
                text="LOAD MORE HOMES"
                onClick={() => {
                  loadMoreHomes();
                }}
                automationId="homeListBtn"
                disabled={homeList?.length <= initialHomes}
              />
            </div>
          )}

          <p className="pricing-disclaimer">
            *Advertised starting sales prices are for the home only. Delivery
            and installation costs are not included unless otherwise stated.
            Starting prices shown on this website are subject to change, see
            your local Home Center for current and specific home and pricing
            information. Sales price does not include other costs such as taxes,
            title fees, insurance premiums, filing or recording fees, land or
            improvements to the land, optional home features, optional delivery
            or installation services, wheels and axles, community or homeowner
            association fees, or any other items not shown on your Sales
            Agreement, Retailer Closing Agreement and related documents (your
            SA/RCA). If you purchase a home, your SA/RCA will show the details
            of your purchase. Homes available at the advertised sales price will
            vary by retailer and state. Artists’ renderings of homes are only
            representations and actual home may vary. Floor plan dimensions are
            approximate and based on length and width measurements from exterior
            wall to exterior wall. We invest in continuous product and process
            improvement. All home series, floor plans, specifications,
            dimensions, features, materials, and availability shown on this
            website are subject to change. Images may show options not included
            in base price.
          </p>
        </div>
      </CuratedCollectionsStyles>
      <RecentlyViewedHomes dealer={dealer} homes={homes} />
      {showRequestModal && (
        <Modal onClose={() => setShowRequestModal(false)}>
          <RequestInfoModalForm
            title={'Get More Information'}
            closeFunction={() => {
              setShowRequestModal(false);
              document.documentElement.classList.remove('modal-lock');
            }}
            isCuratedCoolection={true}
            dealer={dealer}
            homes={homes}
            retailEventLabel={retailEventLabel}
            originWebFormCategory={originWebFormCategory}
            originWebPageType={originWebPageType}
          />
        </Modal>
      )}
      {showScheduleModal && (
        <Modal onClose={() => setShowScheduleModal(false)}>
          <ScheduleVisitModalForm
            title={'SCHEDULE A TOUR'}
            closeFunction={() => {
              setShowScheduleModal(false);
              document.documentElement.classList.remove('modal-lock');
            }}
            isCuratedCoolection={true}
            dealer={dealer}
            homes={homes}
            retailEventLabel={retailEventLabel}
            originWebFormCategory={originWebFormCategory}
            originWebPageType={originWebPageType}
          />
        </Modal>
      )}
    </App>
  );
};

SaleHomes.getInitialProps = async (ctx) => {
  let pageContent;
  let homeList;
  let pathName;

  const {
    dealer,
    homes,
    homesInStock,
    homesOnSale,
    moveInReadyHomes,
    budgetHomes,
  } = await parseInitialPropsContext(ctx, dealerService, homesService);

  const location = ctx.match.url.replaceAll('/', '');

  try {
    const result = await getContent(location, dealer);
    pageContent = result;

    if (location === 'sale-homes') {
      homeList = homesOnSale;
      pathName = location;
    }

    if (location === 'mobile-manufactured-homes-on-display') {
      homeList = homesInStock;
      pathName = location;
    }

    if (location === 'mobile-manufactured-homes-with-land') {
      homeList = moveInReadyHomes;
      pathName = location;
    }

    if (location === 'mobile-manufactured-homes-under-150k') {
      homeList = budgetHomes;
      pathName = location;
    }
  } catch (e) {
    console.error(e);
    return { redirectTo: '/404' };
  }
  return {
    dealer,
    homes,
    homeList,
    homesInStock,
    homesOnSale,
    moveInReadyHomes,
    budgetHomes,
    pageContent,
    pathName,
  };
};

export default SaleHomes;
