import { formatDealerName } from 'pubweb-smokey/dist/utils/formatters';
import React from 'react';
import { Helmet } from 'react-helmet';
import { getNewsAndEvents } from '../../Api.js';
import App from '../../App';
import InventoryClearanceLogo from '../../images/promo-images/inventory-clearance-event-logo.svg';
import * as dealerService from '../../services/dealerService';
import * as homesService from '../../services/homesService';
import { parseInitialPropsContext } from '../../utils';
import { findFeaturedHomes, findTempoHomes } from '../../utils/utils';
import CardGrid from '../Shared/CardGrid/CardGrid';
import FeaturedHomes from '../Shared/FeaturedHomes/FeaturedHomes';
import HeaderWithoutImage from '../Shared/HeaderWithoutImage/HeaderWithoutImage';
import LargeCTA from '../Shared/LargeCTA/LargeCTA';
import XLCTA from '../Shared/XLCTA/XLCTA';
import EventsStructuredData from './EventsStructuredData';
import NewsEventsStyles from './NewsEvents.styled';
import NewsStructuredData from './NewsStructuredData';
import useWindowSize from '../../hooks/useWindowSize';

const NewsEvents = ({
  dealer,
  homes,
  newsAndEvents,
  homesOnSale,
  isLoading,
}) => {
  const featuredHomes = findFeaturedHomes(homes);
  const tempoHomes = findTempoHomes(homes);
  const [width, height] = useWindowSize();
  const isMobile = width <= 1099;

  return (
    <App dealer={dealer} homes={homes} isLoading={isLoading}>
      <NewsEventsStyles>
        <Helmet>
          <title>{formatDealerName(dealer?.dealerName)} News and Events</title>
          <meta
            name="description"
            content={`Find out about news and events related to ${formatDealerName(
              dealer?.dealerName
            )}.`}
          />
          <meta
            name="og:description"
            content={`Find out about news and events related to ${formatDealerName(
              dealer?.dealerName
            )}.`}
          />
        </Helmet>
        <NewsStructuredData newsAndEvents={newsAndEvents} dealer={dealer} />
        <EventsStructuredData newsAndEvents={newsAndEvents} dealer={dealer} />
        {newsAndEvents && (
          <>
            <HeaderWithoutImage largeHeadline="News & Events" />
            <CardGrid items={newsAndEvents} />
            {![
              'BuilderModel',
              'NoSpecialOffersNoEnergySmart',
              'NoSpecialOffers',
            ].includes(dealer?.websiteTemplateDescription) &&
              dealer?.isCorporateAdCampaignParticipant && (
                <LargeCTA
                  key="large-cta"
                  buttonText="View Offer"
                  buttonURL="/special-offers/red-tag-sales-event"
                  backgroundColor="red-tag-sale"
                  linkState={(dealer, homes)}
                  smallText={'RED TAG SALE'}
                  largeText={'Lowest prices of the year!'}
                  headlineWidth={'287px'}
                  backgroundImage={
                    !isMobile && {
                      source: require('../../images/promo-images/red-tag-large-cta.jpg'),
                      srcSet: [
                        {
                          source: require('../../images/webp/red-tag-large-cta.webp'),
                        },
                      ],
                      alt: 'Red Tag Sale Banner Image',
                    }
                  }
                  backgroundPosition={'top center'}
                  gaClass="ga-sale-homes-seasonal-campaign"
                  dealer={dealer}
                  homes={homes}
                  logoText={''}
                  retailEventLabel="news_events_campaign"
                />
              )}

            {(featuredHomes || tempoHomes) && (
              <FeaturedHomes
                featuredHomes={tempoHomes ? tempoHomes : featuredHomes}
                dealer={dealer}
                homes={homes}
                buttonText={'View All Homes'}
                buttonURL={'/homes'}
                secondButtonText={
                  homesOnSale && homesOnSale.length > 0
                    ? 'View Sale Homes'
                    : null
                }
                secondButtonURL={
                  homesOnSale && homesOnSale.length > 0 ? '/sale-homes' : null
                }
                gaClass={'ga-news-view-all-homes'}
                gaClassTwo={'ga-news-home-item'}
                retailEventLabel={'news_events_featured_homes_view_all'}
                retailEventLabelTwo={'news_events_featured_homes_view_sale'}
              />
            )}
            <XLCTA
              largeText="Find Your Perfect Home"
              dealer={dealer}
              homes={homes}
              buttons={[
                {
                  buttonText: 'Get More Information',
                  buttonURL: '/contact-us',
                  gaClass: 'ga-news-get-more-information',
                  openForm: 'requestInfo',
                  retailEventLabel: 'footer_dual_cta_request',
                },
                {
                  buttonText: 'Schedule a Visit',
                  buttonURL: '/contact-us',
                  gaClass: 'ga-news-schedule-a-visit',
                  openForm: 'scheduleVisit',
                  retailEventLabel: 'footer_dual_cta_schedule',
                },
              ]}
            />
          </>
        )}
      </NewsEventsStyles>
    </App>
  );
};

NewsEvents.getInitialProps = async (ctx) => {
  let newsAndEvents;

  const { dealer, homes, homesOnSale } = await parseInitialPropsContext(
    ctx,
    dealerService,
    homesService
  );

  try {
    const result = await getNewsAndEvents(dealer.dealerId);
    newsAndEvents = result;
  } catch (e) {
    console.error(e);
  }

  return { dealer, homes, homesOnSale, newsAndEvents };
};

export default NewsEvents;
