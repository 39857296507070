import React from 'react';
import MissingHomeStyles from './MissingHome.styled';
import { Helmet } from 'react-helmet';
import { formatDealerName } from '../Shared/SmokeyTransitional/utils/formatters';
import { Link } from 'react-router-dom';
import HomeIcon from '../../images/svg/icons/Home';
import Land from '../../images/svg/icons/Land';
import Phone from '../../images/svg/icons/Phone';
import NotFoundGraphic from '../../images/404.png';
import { findFeaturedHomes, findTempoHomes } from '../../utils/utils';
import FeaturedHomes from '../Shared/FeaturedHomes/FeaturedHomes';

const MissingHome = ({ dealer, homes }) => {
  let featuredHomes;

  if (homes.length) {
    featuredHomes = findFeaturedHomes(homes);
  }

  const tempoHomes = findTempoHomes(homes);

  return (
    <>
      <MissingHomeStyles>
        <Helmet>
          <title>Home Not Found | {formatDealerName(dealer?.dealerName)}</title>
          <meta name="robots" content="noindex" />
        </Helmet>

        <div className="container">
          <div className="content">
            <div className="intro">
              {/* <span>Oops, this home is already sold.</span> */}
              <h1>Oops, this home is already sold.</h1>
              <p>Let’s open the door to other options!</p>
            </div>
            <ul className="links">
              <li>
                <Link to={{ pathname: '/homes', state: { dealer } }}>
                  <HomeIcon />
                  <span>Find a Home</span>
                </Link>
              </li>
              <li>
                <Link to={{ pathname: '/contact-us', state: { dealer } }}>
                  <Phone />
                  <span>Contact Us</span>
                </Link>
              </li>
            </ul>
          </div>
          <div className="graphic">
            <img src={NotFoundGraphic} alt="404 graphic" />
          </div>
        </div>
      </MissingHomeStyles>
      {(featuredHomes || tempoHomes) && (
        <FeaturedHomes
          featuredHomes={tempoHomes ? tempoHomes : featuredHomes}
          dealer={dealer}
          homes={homes}
          buttonText={'View All Available Homes'}
          buttonURL={'/homes'}
        />
      )}
    </>
  );
};

export default MissingHome;
