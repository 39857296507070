import styled from 'styled-components';
import Colors from '../../colors';
import * as variables from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';

const CuratedCollectionsStyles = styled.section`
  position: relative;
  padding: 90px 70px 0px;
  @media screen and (max-width: 600px) {
    padding: 70px 15px 0px;
  }
  .no-sale-homes {
    position: relative;
    text-align: center;
    a {
      color: ${Colors.primary.claytonBlue.standard};
    }
  }
  .blue-box {
    position: absolute;
    top: 0;
    left: 0;
    height: 243px;
    width: 100%;
    background: ${Colors.primary.claytonBlue.standard};
  }
  .all-homes {
    position: relative;
    z-index: 7;
    align-items: center;
    color: white;
    text-decoration: none;
    margin-bottom: 10px;
    display: flex;
    width: 100%;
    max-width: 1300px;
    margin: 10px auto;

    span {
      font-family: 'source-sans-pro';
      text-transform: uppercase;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 1px;
    }
  }
  .container {
    position: relative;
    z-index: 5;
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    padding: 40px 20px;
    background: white;
    @media screen and (max-width: 600px) {
      padding: 20px 15px;
    }
  }
  .sale-home-list .cta-container {
    padding: 25px 0px;
    margin-bottom: 20px;
  }
  .intro-content {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .icon {
      width: 45px;
      margin-bottom: 10px;
      height: 45px;
    }
    h1 {
      margin-bottom: 20px;
    }
    p {
      font-size: 14px;
      line-height: 24px;
    }
  }
  .carousel .slide {
    background: transparent;
  }
  .sale-home-list {
    position: relative;
    padding: 0px 15px;
    margin-top: 40px;
    @media screen and (max-width: 500px) {
      padding: 0px;
    }
  }
  .button-container {
    margin-top: 75px;

    button {
      line-height: 1.15;
    }
  }
  .pricing-disclaimer {
    font-family: source-sans-pro;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.69;
    letter-spacing: normal;
    margin-bottom: 105px;
    margin-top: 40px;
  }
  .cta-container {
    width: 100% !important;
    @media only screen and (max-width: 768px) {
      max-width: 100%;
    }
  }

  .request-info {
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    margin-top: 45px;
    height: 83px;

    @media only screen and (min-width: 1025px) {
      width: 286px;
    }

    .request-info-btn {
      height: 50px;
      width: 100%;
      margin: 5px auto 14px;

      @media only screen and (min-width: ${variables.desktop_breakpoint}px) {
        width: 235px;
      }
    }
  }
`;

export default CuratedCollectionsStyles;
