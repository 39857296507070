import styled from 'styled-components';
import Colors from '../../colors';
import PromoBannerClayton from '../../images/promo-images/fall-promo/red-tag-sale-background.webp';
import PromoBannerClaytonTablet from '../../images/promo-images/fall-promo/red-tag-sale-background-tablet.webp';
import PromoBannerClaytonMobile from '../../images/promo-images/fall-promo/red-tag-sale-background-mobile.webp';

export const StyledBanner = styled.div`
  width: 100%;

  @media only screen and (min-width: 1025px) {
    display: flex;
    justify-content: space-between;
  }

  .banner {
    background-color: #fefbfc;
    position: relative;
    background: url(${PromoBannerClaytonMobile}) no-repeat top center;
    background-size: cover !important;
    height: 360px;
    padding: 13px 0 0;
    box-sizing: border-box;
    text-align: center;

    @media only screen and (min-width: 400px) {
      height: 400px;
    }

    @media only screen and (min-width: 500px) {
      height: 600px;
    }

    @media only screen and (min-width: 768px) {
      height: 234px;
      background: url(${PromoBannerClaytonTablet}) no-repeat top center;
      padding: 15px 0 90px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      text-align: unset;
    }

    @media only screen and (min-width: 1025px) {
      height: auto;
      width: 50%;
      background: url(${PromoBannerClayton}) no-repeat top center;
      min-height: 800px;
      padding: 36px 0 0;
      display: unset;
    }

    @media only screen and (min-width: 1600px) {
      min-height: calc(100vh - 100px);
      background-position: center;
      overflow: hidden;
    }

    .banner-headline {
      font-size: 41px;
      line-height: 1;
      font-weight: 800;
      letter-spacing: -0.35px;
      color: ${Colors.primary.white.standard};
      font-family: 'source-sans-pro', sans-serif;
      width: 90%;
      margin: 0 auto;

      @media only screen and (min-width: 768px) {
        font-size: 70px;
        flex: 1 0 100%;
        max-width: 525px;
        margin: 0;
        max-height: 64px;
      }

      @media only screen and (min-width: 1025px) {
        font-size: 92px;
        width: 90%;
        margin: 0 auto;
        max-width: none;
        max-height: none;
      }
    }

    .banner-subheadline {
      font-size: 25px;
      line-height: 1;
      font-weight: 700;
      letter-spacing: -0.35px;
      color: ${Colors.primary.white.standard};
      font-family: 'source-sans-pro', sans-serif;
      width: 90%;
      margin: 0 auto;

      @media only screen and (min-width: 768px) {
        font-size: 44px;
        flex: 1 0 100%;
        max-width: 525px;
        max-height: 38px;
        margin: 0;
      }

      @media only screen and (min-width: 1025px) {
        font-size: 56px;
        width: 90%;
        margin: 0 auto;
        max-height: none;
        max-width: none;
      }
    }

    .banner-disclaimer {
      font-size: 8px;
      line-height: 10px;
      font-weight: 700;
      color: ${Colors.primary.white.standard};
      font-family: 'source-sans-pro', sans-serif;
      width: 90%;
      margin: 10px auto 0;

      @media only screen and (min-width: 768px) {
        font-size: 11px;
        line-height: 14px;
        flex: 1 0 100%;
        max-width: 525px;
        margin: 10px 0 0;
      }

      @media only screen and (min-width: 1025px) {
        font-size: 14px;
        line-height: 16px;
        width: 90%;
        margin: 10px auto 0;
        max-width: none;
      }

      a {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    img {
      width: 638px;
      max-width: 90%;
      height: auto;
    }

    .offer-extended-logo-mobile {
      max-width: 158px;
      position: absolute;
      right: 20px;
    }
  }
`;
