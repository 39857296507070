import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Colors from '../../../colors';
import { DXAccountContext } from '../../../contexts/DXAccountContext';
import DualImageCTALeftShape from '../../../images/DualImageCTALeftShape.svg';
import DualImageCTARightShape from '../../../images/DualImageCTARightShape.svg';
import FallbackCTA2 from '../../../images/promo-cta-background-2.jpg';
import FallbackCTA1 from '../../../images/promo-cta-background.png';
import { fireSegmentEvent } from '../../../utils';
import OptimizedImage from '../OptimizedImage/OptimizedImage';
import DualImageCTAStyles from './DualImageCTA.styled';

export const DualImageCTA = ({
  slideOneSmallText,
  slideOneLargeText,
  slideOneButtonText,
  slideOneButtonURL,
  slideOneButtonClass,
  slideOneBackgroundImage,
  slideTwoSmallText,
  slideTwoLargeText,
  slideTwoButtonText,
  slideTwoButtonURL,
  slideTwoBackgroundImage,
  aTagLink,
  backgroundColor,
  gaClass,
  gaSecondClass,
  dealer,
  homes,
  portalLink,
}) => {
  const accountContext = useContext(DXAccountContext);

  const homesOnSale = homes?.filter(
    (home) => home.isSaleHome == true || home.isDefaultSaleHome == true
  );

  return (
    <DualImageCTAStyles>
      <div className={`cta-container ${backgroundColor}-background`}>
        <img
          src={DualImageCTARightShape}
          className="right-pattern"
          alt="right decorative pattern"
          loading="lazy"
        />
        <img
          src={DualImageCTALeftShape}
          className="left-pattern"
          alt="left decorative pattern"
          loading="lazy"
        />
        <div className="dual-image-cta">
          {dealer?.websiteTemplateDescription !== 'NoSpecialOffers' &&
          dealer?.websiteTemplateDescription !== 'BuilderModel' &&
          dealer?.websiteTemplateDescription !==
            'NoSpecialOffersNoEnergySmart' ? (
            <div className="content">
              <OptimizedImage
                src={slideOneBackgroundImage?.source}
                srcSet={slideOneBackgroundImage?.srcSet}
                alt={slideOneBackgroundImage?.alt}
                lazy
              />
              {slideOneSmallText && (
                <p className="h3 small-text">{slideOneSmallText}</p>
              )}
              {slideOneLargeText && (
                <p className="h2 large-text promo">{slideOneLargeText}</p>
              )}
              {slideOneButtonURL && slideOneButtonText && !aTagLink && (
                <div className="btn-wrap">
                  <Link
                    to={{
                      pathname: slideOneButtonURL,
                      state: { dealer, homes },
                    }}
                    className={
                      'cta-button ' +
                      (gaClass ? gaClass : '') +
                      ' ' +
                      (slideOneButtonClass ? slideOneButtonClass : '')
                    }
                    onClick={() => {
                      fireSegmentEvent('CTA Clicked', {
                        isNav: false,
                        type: 'button',
                        text: slideOneButtonText,
                        eventContext: 'home_promotion_cards_image_1',
                      });
                    }}
                  >
                    {slideOneButtonText}
                  </Link>
                </div>
              )}
              {slideOneButtonURL && slideOneButtonText && aTagLink && (
                <div className="btn-wrap">
                  <a
                    href={slideOneButtonURL}
                    className={
                      'cta-button ' +
                      (gaClass ? gaClass : '') +
                      (slideOneButtonClass ? slideOneButtonClass : '')
                    }
                  >
                    {slideOneButtonText}
                  </a>
                </div>
              )}
            </div>
          ) : (
            <>
              {dealer?.websiteTemplateDescription !== 'BuilderModel' &&
              homesOnSale &&
              homesOnSale.length > 0 ? (
                <div
                  className="content"
                  style={{
                    backgroundImage: `url(${FallbackCTA1})`,
                  }}
                >
                  <p className="h3 small-text">SALE HOMES</p>
                  <p className="h2 large-text">Discover sale homes today</p>
                  <div className="btn-wrap">
                    <Link
                      to={{ pathname: '/sale-homes', state: { dealer, homes } }}
                      className={'cta-button ' + (gaClass ? gaClass : '')}
                      onClick={() => {
                        fireSegmentEvent('CTA Clicked', {
                          isNav: false,
                          type: 'button',
                          text: 'View Sale Homes',
                          eventContext: 'home_promotion_cards_image_1',
                        });
                      }}
                    >
                      View Sale Homes
                    </Link>
                  </div>
                </div>
              ) : (
                <div
                  className="content"
                  style={{
                    backgroundImage: `url(${FallbackCTA2})`,
                  }}
                >
                  <p className="h3 small-text">CONTACT US</p>
                  <p className="h2 large-text">
                    Contact us today to learn more
                  </p>
                  <div className="btn-wrap">
                    <Link
                      to={{ pathname: '/contact-us', state: { dealer, homes } }}
                      className={'cta-button ' + (gaClass ? gaClass : '')}
                      onClick={() => {
                        fireSegmentEvent('CTA Clicked', {
                          isNav: false,
                          type: 'button',
                          text: 'Contact Us',
                          eventContext: 'home_promotion_cards_image_1',
                        });
                      }}
                    >
                      Contact Us
                    </Link>
                  </div>
                </div>
              )}
            </>
          )}
          <div className="content">
            <OptimizedImage
              src={slideTwoBackgroundImage?.source}
              srcSet={slideTwoBackgroundImage?.srcSet}
              alt={slideTwoBackgroundImage?.alt}
              lazy
            />
            {slideTwoSmallText && (
              <p className="h3 small-text">{slideTwoSmallText}</p>
            )}
            {slideTwoLargeText && (
              <p className="h2 large-text">{slideTwoLargeText}</p>
            )}
            {portalLink ? (
              <>
                {accountContext?.state.isAuthenticated ? (
                  <div className="btn-wrap">
                    <Link
                      to={slideTwoButtonURL}
                      className={
                        'cta-button ' + (gaSecondClass ? gaSecondClass : '')
                      }
                      onClick={() => {
                        fireSegmentEvent('CTA Clicked', {
                          isNav: false,
                          type: 'button',
                          text: slideTwoButtonText,
                          eventContext: 'home_promotion_cards_image_2',
                        });
                      }}
                    >
                      {slideTwoButtonText}
                    </Link>
                  </div>
                ) : (
                  <>
                    <div className="btn-wrap">
                      <div
                        onClick={() =>
                          accountContext.actions.showLogin('/portal/planner') +
                          fireSegmentEvent('CTA Clicked', {
                            isNav: false,
                            type: 'button',
                            text: slideTwoButtonText,
                            eventContext: 'home_promotion_cards_image_2',
                          })
                        }
                        className={
                          'cta-button ' + (gaSecondClass ? gaSecondClass : '')
                        }
                      >
                        {slideTwoButtonText}
                      </div>
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                {slideTwoButtonURL && slideTwoButtonText && !aTagLink && (
                  <div className="btn-wrap">
                    <Link
                      to={slideTwoButtonURL}
                      className={
                        'cta-button ' + (gaSecondClass ? gaSecondClass : '')
                      }
                    >
                      {slideTwoButtonText}
                    </Link>
                  </div>
                )}
                {slideTwoButtonURL && slideTwoButtonText && aTagLink && (
                  <div className="btn-wrap">
                    <a
                      href={slideTwoButtonURL}
                      className={
                        'cta-button ' + (gaSecondClass ? gaSecondClass : '')
                      }
                    >
                      {slideTwoButtonText}
                    </a>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </DualImageCTAStyles>
  );
};

DualImageCTA.props = {
  slideOneSmallText: PropTypes.string,
  slideOneLargeText: PropTypes.string,
  slideOneButtonText: PropTypes.string,
  slideOneButtonURL: PropTypes.string,
  slideOneBackgroundImage: PropTypes.string,
  slideTwoSmallText: PropTypes.string,
  slideTwoLargeText: PropTypes.string,
  slideTwoButtonText: PropTypes.string,
  slideTwoButtonURL: PropTypes.string,
  slideTwoBackgroundImage: PropTypes.string,
  aTagLink: PropTypes.bool,
  backgroundColor: PropTypes.string,
  backgroundImage: PropTypes.string,
  gaClass: PropTypes.string,
  gaSecondClass: PropTypes.string,
};

DualImageCTA.defaultProps = {
  backgroundColor: `${Colors.secondary.kiddiePool.standard}`,
};

export default DualImageCTA;
