import styled from 'styled-components';
import Colors from '../../colors';
import PromoBannerClayton from '../../images/promo-images/fall-promo/red-tag-sale-confirmation-background.webp';
import PromoBannerClaytonTablet from '../../images/promo-images/fall-promo/red-tag-sale-confirmation-background-tablet.webp';
import PromoBannerClaytonMobile from '../../images/promo-images/fall-promo/red-tag-sale-confirmation-background-mobile.webp';

export const StyledFallPromoThankYou = styled.div`
  .ty-message-banner {
    width: 100%;

    @media only screen and (min-width: 1025px) {
      display: flex;
      justify-content: space-between;
    }
  }

  .loading-block {
    min-height: calc(100vh - 89px);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .banner {
    background-color: #fefbfc;
    position: relative;

    @media only screen and (min-width: 1025px) {
      width: 50%;
    }

    .banner-img {
      // position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: auto;
      object-fit: cover;
      max-width: none;
    }

    .banner-headline {
      font-size: 23px;
      line-height: 27px;
      font-weight: 400;
      letter-spacing: -0.35px;
      color: ${Colors.primary.white.standard};
      font-family: 'source-sans-pro', sans-serif;
      width: 90%;
      margin: 14px 0 0;
      padding-left: 29px;

      @media only screen and (min-width: 768px) {
        width: 69%;
        max-width: 496px;
        font-size: 32px;
        line-height: 1.19;
        margin-top: 2px;
        padding-left: 37px;
      }

      @media only screen and (min-width: 1025px) {
        margin-top: 26px;
        padding-left: 55px;
      }

      .bold {
        font-weight: 700;
      }
    }

    .banner-disclaimer {
      font-size: 14px;
      line-height: 16px;
      font-weight: 400;
      color: ${Colors.primary.white.standard};
      font-family: 'source-sans-pro', sans-serif;
      width: 40%;
      margin-left: 6%;

      @media only screen and (min-width: 768px) {
        font-size: 16px;
        line-height: 20px;
        max-width: 244px;
        width: 32%;
        margin-left: 128px;
      }

      @media only screen and (min-width: 1025px) {
        text-align: center;
        margin: 0 auto;
        width: 72%;
        max-width: 520px;
      }

      a {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    img {
      width: 638px;
      max-width: 90%;
      height: auto;
    }

    .offer-extended-logo-mobile {
      max-width: 158px;
      position: absolute;
      right: 20px;
    }
  }

  .confirmation-message {
    background: ${Colors.primary.white.standard};
    box-shadow: 0 5px 21px 0 rgba(109, 110, 113, 0.13);
    width: 100%;
    margin: auto;
    padding: 80px 0 173px;

    @media screen and (min-width: 768px) {
      padding: 154px 0;
    }

    @media screen and (min-width: 1025px) {
      max-width: none;
      width: 50%;
      margin: unset;
    }

    @media screen and (min-width: 1380px) {
      padding-top: 150px;
    }

    @media screen and (min-width: 1600px) {
      padding-top: 243px;
    }
  }

  .message-container {
    h3 {
      font-size: 25px;
      line-height: 30px;
      font-weight: 700;
      font-family: 'acumin-pro', sans-serif;
      color: ${Colors.primary.black.standard};
      letter-spacing: -0.35px;
      text-align: center;
      margin-block-start: 0;
      margin-block-end: 0;
      margin: 0 auto 71px;
    }

    .house-container {
      max-height: 181px;
      max-width: 155px;
      margin: 0 auto 23px;
      text-align: center;
    }

    p {
      font: 400 18px/29px 'SourceSans-Pro Regular', sans-serif;
      color: ${Colors.accent.grey1.standard};
      text-align: center;
      margin: 0 auto 70px;
      width: 87%;

      @media only screen and (min-width: 768px) {
        width: auto;
      }

      br {
        display: none;

        @media only screen and (min-width: 768px) {
          display: block;
        }
      }
    }

    .button {
      font: 600 14px/100% 'Source Sans Pro', sans-serif;
      color: ${Colors.primary.white.standard};
      text-align: center;
      width: 87%;
      border-radius: 2px;
      background: ${Colors.accent.redTagRed.standard};
      display: block;
      padding: 18px 0;
      margin: 0 auto;
      text-decoration: none;
      text-transform: uppercase;
      max-width: 327px;

      @media only screen and (min-width: 768px) {
        max-width: none;
        width: 392px;
      }

      &:hover {
        cursor: pointer;
        opacity: 0.8;
        background: ${Colors.accent.redTagRed.standard} !important;
      }
    }
  }
  .explore-more-to-save {
    position: relative;
    height: 191px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    h2 {
      position: absolute;
      color: ${Colors.primary.white.standard};
      font-size: 48px;
      line-height: 1;
      letter-spacing: -0.35px;
    }
  }
`;
