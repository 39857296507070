import styled from 'styled-components';
import Colors from '../../colors';

const FallPromoStyles = styled.div`
  .spacer {
    position: relative;
    margin: 80px 0px;
    display: flex;
    @media only screen and (max-width: 775px) {
      margin: 40px 0px;
    }
  }
  .explore-more-to-save {
    position: relative;
    height: 191px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    h2 {
      position: absolute;
      color: ${Colors.primary.white.standard};
      font-size: 48px;
      line-height: 1;
      letter-spacing: -0.35px;

      @media screen and (min-width: 768px) {
        font-size: 40px;
      }

      @media screen and (min-width: 1025px) {
        font-size: 48px;
      }
    }
  }
  .capitalize-first-letter {
    text-transform: capitalize;
  }
`;

export default FallPromoStyles;
