import PropTypes from 'prop-types';
import Button from 'pubweb-smokey/dist/components/Buttons/Button';
import React, { useState } from 'react';
import { contactFormSubmit } from '../../../Api';
import { getUTMParametersFromSessionCache } from '../../../components/Shared/SmokeyTransitional/utils/utils';
import Loader from '../../../images/svg/loader';
import { fireSegmentEvent, formatPhoneNumber } from '../../../utils';
import { getGAClientId } from '../../../utils/config';
import { fireMNTNConversionPixel } from '../../../utils/utils';
import TextArea from '../../Shared/SmokeyTransitional/components/TextArea/TextArea';
import TextField from '../../Shared/SmokeyTransitional/components/TextField/TextField';
import ContactThankYou from '../ContactThankYou/ContactThankYou';
import RequestInfoFormStyles from './RequestInfoForm.styled';
import { scrollIntoViewHelper } from '../../../utils/utils';

export const RequestInfoForm = ({
  title,
  ty,
  dealer,
  clearThankYouPage,
  showThankYouCallBack,
  isBuilderModelForm,
}) => {
  const [firstName, setFirstName] = useState({ value: '', error: null });
  const [lastName, setLastName] = useState({ value: '', error: null });
  const [phone, setPhone] = useState({ value: '', error: null });
  const [email, setEmail] = useState({ value: '', error: null });
  const [leadComments, setLeadComments] = useState({ value: '' });
  const [showThankYou, setShowThankYou] = useState(ty || false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const clearThankYouPageBuilder = () => {
    setFirstName({ value: '', error: null });
    setLastName({ value: '', error: null });
    setPhone({ value: '', error: null });
    setEmail({ value: '', error: null });
    setLeadComments({ value: '' });
    setShowThankYou(false);
  };

  let utmParams;
  try {
    utmParams = getUTMParametersFromSessionCache();
  } catch (error) {
    utmParams = {};
  }

  const firstnameIsValid = () => {
    let isValid = true;

    if (!firstName.value || firstName.value.length < 2) {
      setFirstName({ ...firstName, error: '*Please enter your first name.' });
      isValid = false;
    } else if (!firstName.value.match(/^[a-zA-Z ,.'-]+$/i)) {
      setFirstName({ ...firstName, error: '*Please enter your first name.' });
      isValid = false;
    } else {
      setFirstName({ ...firstName, error: null });
    }

    return isValid;
  };

  const lastnameIsValid = () => {
    let isValid = true;

    if (!lastName.value || lastName.value.length < 2) {
      setLastName({ ...lastName, error: '*Please enter your last name.' });
      isValid = false;
    } else if (!lastName.value.match(/^[a-zA-Z ,.'-]+$/i)) {
      setLastName({ ...lastName, error: '*Please enter your last name.' });
      isValid = false;
    } else {
      setLastName({ ...lastName, error: null });
    }

    return isValid;
  };

  const emailIsValid = () => {
    let isValid = true;
    const pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    if (!email.value) {
      isValid = false;
      setEmail({ ...email, error: '*Please enter your email.' });
    } else if (!pattern.test(email.value)) {
      isValid = false;
      setEmail({ ...email, error: '*Please enter a valid email.' });
    } else {
      setEmail({ ...email, error: '' });
    }

    return isValid;
  };

  const phoneIsValid = () => {
    let isValid = true;

    if (!phone.value) {
      isValid = false;
      setPhone({ ...phone, error: '*Please enter your phone number.' });
    } else if (
      !phone.value.match(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/i)
    ) {
      isValid = false;
      setPhone({ ...phone, error: '*Please enter a valid phone number.' });
    } else {
      setPhone({ ...phone, error: '' });
    }

    return isValid;
  };

  const validateForm = () => {
    let formIsValid = true;
    let errorFields = {};

    //all must be true to submit, so bitwise 'and' it is
    formIsValid &= firstnameIsValid() ? true : !(errorFields.firstName = true);

    formIsValid &= lastnameIsValid() ? true : !(errorFields.lastName = true);

    formIsValid &= emailIsValid() ? true : !(errorFields.email = true);

    formIsValid &= phoneIsValid() ? true : !(errorFields.phone = true);

    return [formIsValid, errorFields];
  };

  const formSubmitHandler = async () => {
    const [formIsValid, errorFields] = validateForm();

    if (!formIsValid) {
      scrollIntoViewHelper(errorFields);
      return;
    }

    const formData = {
      firstName: firstName.value,
      lastName: lastName.value,
      phoneNumber: phone.value,
      emailAddress: email.value,
      leadComments: leadComments.value,
      dataExtension: 'FormActivityRetail',
      dealerNumber: dealer.dealerNumber,
      canEmail: true,
      doNotCall: false,
      originWebURL: window?.location?.href,
      originWebApp: 'clayton-retail-website',
      originWebPageType: isBuilderModelForm
        ? 'retail-builder-homepage'
        : 'retail-contact-us',
      originWebFormType: 'lead-form',
      originWebFormCategory: isBuilderModelForm
        ? 'homepage-contact-us'
        : 'contact-us-info',
      utmSource: utmParams.utm_source || '',
      utmCampaign: utmParams.utm_campaign || '',
      utmTerm: utmParams.utm_term || '',
      utmContent: utmParams.utm_content || '',
      utmMedium: utmParams.utm_medium || '',
      kenshooId: utmParams.kenshooid || '',
      gaClientId: getGAClientId() || '',
      claytonAccountId: '',
      googleClickId: utmParams.gclid || '',
      bingClickId: utmParams.msclkid || '',
      facebookClickId: utmParams.fbclid || '',
    };

    setIsSubmitting(true);
    const formSubmitted = await contactFormSubmit(formData);

    if (formSubmitted.status === 200) {
      setShowThankYou(true);
      setIsSubmitting(false);
      showThankYouCallBack(showThankYou);
      fireMNTNConversionPixel();
      if (dataLayer) {
        dataLayer.push({
          event: isBuilderModelForm
            ? 'ga-buildermodel-submit-inquiry'
            : 'ga-contact-submit-inquiry',
        });
      }
      fireSegmentEvent('Generic Contact Form Submitted', {
        formId: 'contact_us_form_submit_request',
        email: '',
        firstName: '',
        lastName: '',
        phone: '',
        comments: '',
        eventContext: 'contact_us_form_submit_request',
      });
    }
  };

  if (showThankYou) {
    return (
      <ContactThankYou
        label="Requesting Information"
        title={title}
        closeFunction={
          isBuilderModelForm ? clearThankYouPageBuilder : clearThankYouPage
        }
        dealer={dealer}
      />
    );
  }

  return (
    <RequestInfoFormStyles className="request-info-form-wrapper">
      <form>
        <TextField
          id="firstName"
          className="half"
          textFieldStyle="primary"
          label="First name"
          value={firstName.value}
          errorMessage={firstName.error}
          onChange={(event) => {
            setFirstName({ value: event.target.value, error: null });
          }}
          onBlur={firstnameIsValid}
          maxLength="40"
        />
        <TextField
          id="lastName"
          className="half"
          textFieldStyle="primary"
          label="Last name"
          value={lastName.value}
          errorMessage={lastName.error}
          onChange={(event) => {
            setLastName({ value: event.target.value, error: null });
          }}
          onBlur={lastnameIsValid}
          maxLength="50"
        />
        <TextField
          id="phone"
          className="half"
          textFieldStyle="primary"
          label="Phone number"
          value={formatPhoneNumber(phone.value)}
          errorMessage={phone.error}
          onChange={(event) => {
            setPhone({ value: event.target.value, error: null });
          }}
          onBlur={phoneIsValid}
          type="tel"
          minLength="10"
          maxLength="10"
        />
        <TextField
          id="email"
          className="half"
          textFieldStyle="primary"
          label="Email"
          value={email.value}
          errorMessage={email.error}
          onChange={(event) => {
            setEmail({ value: event.target.value, error: null });
          }}
          onBlur={emailIsValid}
          maxLength="80"
        />
        <TextArea
          id="leadComments"
          label={
            isBuilderModelForm ? 'Message' : 'Comments (Max 500 characters)'
          }
          inputName="leadComments"
          value={leadComments.value}
          onChange={(event) => {
            setLeadComments({ value: event.target.value });
          }}
        />
      </form>
      <div className="button-disclaimer-wrap">
        <Button
          text={
            isSubmitting ? (
              <Loader />
            ) : isBuilderModelForm ? (
              'Submit'
            ) : (
              'Request Info'
            )
          }
          onClick={isSubmitting ? () => {} : formSubmitHandler}
          disabled={isSubmitting}
          automationId="requestInfoButton"
        />
        <p className="disclaimer">
          By submitting this form, I agree that Clayton may contact me by
          telephone, provide me with marketing communications about Clayton
          products and services, and share my contact information as described
          in our website terms.
        </p>
      </div>
    </RequestInfoFormStyles>
  );
};

RequestInfoForm.propTypes = {
  title: PropTypes.string.isRequired,
};

export default RequestInfoForm;
