import Colors from 'pubweb-smokey/dist/colors';
import { formatDealerName } from 'pubweb-smokey/dist/utils/formatters';
import React, { useRef } from 'react';
import { Helmet } from 'react-helmet';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { Redirect } from 'react-router-dom';
import { getNewsAndEvents } from '../../Api.js';
import App from '../../App';
import LongFormCtaBackground from '../../images/long-form-cta-background.jpg';
import VideoBackgroundImage from '../../images/promo-images/energy-smart-zero-video-banner.jpg';
import HeaderWithHeroImage from '../../images/promo-images/inventory-clearance-event-banner.jpg';
import InventoryClearanceEventLogo from '../../images/svg/promo-logos/inventory-clearance-event-logo.webp';
import * as dealerService from '../../services/dealerService';
import * as homesService from '../../services/homesService';
import { parseInitialPropsContext } from '../../utils';
import { findMetaDescription } from '../../utils/utils';
import FeaturedEvent from '../Shared/FeaturedEvent/FeaturedEvent';
import LongFormCTA from '../Shared/LongFormCTA/LongFormCTA';
import SaleHomeSlider from '../Shared/SaleHomeSlider/SaleHomeSlider';
import SpecialOffersHero from '../Shared/SpecialOffersHero/SpecialOffersHero';
import TextContent from '../Shared/TextContent/TextContent';
import Video from '../Shared/Video/Video';
import XLCTA from '../Shared/XLCTA/XLCTA';
import SpecialOffersStyles from './SpecialOffers.styled';

//talk to PO about whether to remove or set to false after current promo (eBuilt) ends
const isPromoActive = true;

const SpecialOffers = ({
  dealer,
  homes,
  homesOnSale,
  featuredEvent,
  isLoading,
}) => {
  return <Redirect to="/special-offers/red-tag-sales-event" />;
  const disclaimerRef = useRef();
  const handleScroll = () => {
    window.scrollTo({
      top: disclaimerRef.current.offsetTop,
      behavior: 'smooth',
    });
  };
  const isClayton = () => {
    if (dealer?.brandAcronym.toLowerCase() === 'cla') {
      return true;
    }
    return false;
  };

  const videoId = () => {
    if (isClayton()) {
      return '2mh3mbrn3d';
    }
    if (!isClayton()) {
      return '2mh3mbrn3d';
    }
    return '';
  };

  if (dealer?.websiteTemplateDescription == 'BuilderModel') {
    return <Redirect to="/" />;
  }

  if (dealer?.websiteTemplateDescription === 'NoSpecialOffers') {
    return <Redirect to="/sale-homes" />;
  }

  if (isPromoActive) {
    return <Redirect to="/special-offers/red-tag-sales-event" />;
  }

  const customPageMetaDescription = findMetaDescription(
    dealer?.websiteMetaDescriptions,
    'special-offers'
  );

  return (
    <App dealer={dealer} homes={homes} isLoading={isLoading}>
      <SpecialOffersStyles>
        <Helmet>
          <title>
            Mobile Home Special Offers | {formatDealerName(dealer?.dealerName)}
          </title>
          <meta
            name="description"
            content={customPageMetaDescription?.metaDescription}
          />
          <meta
            name="og:description"
            content={customPageMetaDescription?.metaDescription}
          />
        </Helmet>

        <SpecialOffersHero
          largeHeadline={
            <>
              SAVE THOUSANDS
              <br /> on select in-stock homes*
            </>
          }
          disclaimer={
            '*Sale homes will vary by retailer and state. See local retailer for list of available homes.'
          }
          image={{
            source: HeaderWithHeroImage,
            alt: 'Inventory Clearance Event hero image',
          }}
          promoLogo={{
            source: InventoryClearanceEventLogo,
            alt: 'Inventory Clearacne Event Logo',
          }}
          buttonText={'View Deals'}
          buttonURL={'/sale-homes'}
          dealer={dealer}
          homes={homes}
          gaClass={'ga-special-offers-learn-more'}
          retailEventLabel={'special_offers_hero'}
          homesOnSale={homesOnSale}
        />

        {homesOnSale && homesOnSale.length > 0 && (
          <LazyLoadComponent>
            <SaleHomeSlider
              largeHeadline={'Explore Homes'}
              saleHomes={homesOnSale}
              buttonText={'View All Sale Homes'}
              buttonURL={'/sale-homes'}
              gaClass={'ga-special-offers-view-sale-homes'}
              retailEventLabel={'special_offers_explore_homes_view_all'}
              dealer={dealer}
            />
          </LazyLoadComponent>
        )}
        <TextContent
          smallHeadline={'INVENTORY CLEARANCE EVENT'}
          largeHeadline={'Shop now before these sale homes are gone!'}
          content={
            <>
              We’re making room for new display homes which means you get to
              take advantage of great deals on existing inventory. Save
              thousands on select in-stock homes* while they last!
            </>
          }
          smallDisclaimer={
            '*Sale homes will vary by retailers and state. See local retailer for a list of eligible homes.'
          }
          backgroundColor={Colors.secondary.grey6.standard}
          mobileBackgroundColor={Colors.secondary.grey6.standard}
          largePadding={true}
        />
        <LazyLoadComponent>
          <Video
            // smallHeadline={'Smart Home Savings Event'}
            // largeHeadline={'Watch the Commercial'}
            gaClass={'ga-special-offers-video'}
            retailEventLabel={'special_offers_video'}
            videoID={videoId()}
            image={{
              source: VideoBackgroundImage,
              alt: 'inventory clearance event video image',
            }}
            wistiaOptions={'silentAutoPlay=false autoPlay=true'}
            isSpecialOffers={true}
          />
        </LazyLoadComponent>
        {featuredEvent && (
          <FeaturedEvent
            buttonText={'Learn More'}
            smallHeadline={'Upcoming Events'}
            featuredEvent={featuredEvent}
            isSpecialOffers={true}
          />
        )}
        <LongFormCTA
          largeText="We accept trades!"
          smallText="Find a new home you love? Contact us for more information about our trade-in requirements and to schedule your free trade evaluation."
          buttonText="Learn More"
          buttonUrl="/special-offers/trade-in"
          gaClass="ga-learn-trades"
          backgroundImage={LongFormCtaBackground}
          dealer={dealer}
          homes={homes}
          noFeaturedEvent={!featuredEvent}
        />
        <XLCTA
          largeText="Find Your Perfect Home"
          buttons={[
            {
              buttonText: 'Get More Information',
              buttonURL: '/contact-us',
              gaClass: 'ga-special-offers-get-more-information',
              openForm: 'requestInfo',
              retailEventLabel: 'footer_dual_cta_request',
            },
            {
              buttonText: 'Schedule a Visit',
              buttonURL: '/contact-us',
              gaClass: 'ga-special-offers-schedule-a-visit',
              openForm: 'scheduleVisit',
              retailEventLabel: 'footer_dual_cta_schedule',
            },
          ]}
        />
      </SpecialOffersStyles>
    </App>
  );
};

SpecialOffers.getInitialProps = async (ctx) => {
  let featuredEvent;

  const { dealer, homes, homesOnSale } = await parseInitialPropsContext(
    ctx,
    dealerService,
    homesService
  );

  try {
    const result = await getNewsAndEvents(dealer.dealerId);
    featuredEvent = result.filter((event) => event.isFeatured)[0];
  } catch (e) {
    console.error(e);
  }

  return { dealer, homes, homesOnSale, featuredEvent };
};

export default SpecialOffers;
