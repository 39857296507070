import BuiltSmartImage from './images/builtsmart-promo.webp';
import DesignSmartImage from './images/designsmart-promo.webp';
import EnergySmartImage from './images/energysmart-promo.webp';
import BudgetSmartImage from './images/budgetsmart-promo.webp';

import BuiltSmartIcon from './icons/BuiltSmart.svg';
import DesignSmartIcon from './icons/DesignSmart.svg';
import EnergySmartIcon from './icons/EnergySmart.svg';
import BudgetSmartIcon from './icons/BudgetSmart.svg';

const HouseSmartPromoGridContent = {
  mainHeadline: 'The HouseSmart® Difference',
  columns: [
    {
      color: 'red',
      image: BuiltSmartImage,
      title: 'BuiltSmart®',
      content:
        ' We use quality products from the world’s most trusted brands, so your home is built to last*. We’re also building a better tomorrow through environmental management, renewable energy and waste reduction, which can lower costs for you.',
      disclaimer: '*With proper home maintenance.',
      boxQuote:
        'If a family member wanted to know if a Clayton home was the way to go, I would say go for it.',
      boxAttribution: '– Robert W. from Mars Hill, NC',
      boxIcon: BuiltSmartIcon,
      tradeMarkDisclaimer:
        'Trademarks of companies other than Clayton are the property of those other companies. ENERGY STAR® and the ENERGY STAR® mark are registered trademarks owned by the U.S. Environmental Protection Agency.',
    },
    {
      color: 'purple',
      image: DesignSmartImage,
      title: 'DesignSmart®',
      content:
        'Smart design features are the difference between a house and a dream home. Its open spaces that inspire togetherness, storage solutions to make life easier, stylish exterior options and spa bathrooms, all designed with you in mind.',
      boxQuote:
        'I never knew manufactured homes could be like this. We took a look around and just couldn’t get over it.',
      boxAttribution: '– Bonny T. from Greeneville, TN',
      boxIcon: DesignSmartIcon,
    },
    {
      color: 'teal',
      image: EnergySmartImage,
      title: 'EnergySmart Zero™',
      content:
        'Featuring eBuilt®, our EnergySmart Zero™ homes are more efficient than ever helping you save tens of thousands** over the life of your home. Now available on every new Clayton Built® home.',
      boxQuote:
        'Everyone that sees my house falls in love with it. I am so happy.',
      boxAttribution: '– Crystal B, from Swansboro, NC',
      boxIcon: EnergySmartIcon,
      sourceLink: 'https://bit.ly/3NrAuTi',
    },
    {
      color: 'yellow',
      image: BudgetSmartImage,
      title: 'BudgetSmart®',
      content:
        'We’re working to make the dream of homeownership possible for everyone. Our precise building process and energy efficient features can save you money so you can open doors to a better life.',
      boxQuote:
        'We’ve bought a lot of houses through the years, and we looked for what’s going to hold its value. We want to see our investment and our children’s investment out of it.',
      boxAttribution: '– Judy P. from New Tazewell, TN',
      boxIcon: BudgetSmartIcon,
    },
  ],
};

export default HouseSmartPromoGridContent;
