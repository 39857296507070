import React from 'react';
import Form from './Form';
import { StyledBanner } from './Banner.Style';

const Banner = ({
  bannerHeadline,
  bannerSubHeadline,
  bannerDisclaimer,
  formText,
  formDisclaimer,
  showThankYouCallBack,
  isClayton,
  dealer,
  homes,
}) => {
  // const [width, height] = useWindowSize();
  // const isTablet = width <= 1023;
  // const isMobile = width <= 767;
  return (
    <StyledBanner>
      <div className="banner">
        <h1 className="banner-headline">{bannerHeadline}</h1>
        <h2 className="banner-subheadline">{bannerSubHeadline}</h2>
        <p className="banner-disclaimer">{bannerDisclaimer}</p>
      </div>
      <Form
        formText={formText}
        formDisclaimer={formDisclaimer}
        isClayton={isClayton}
        dealer={dealer}
        showThankYouCallBack={showThankYouCallBack}
      />
    </StyledBanner>
  );
};

export default Banner;
