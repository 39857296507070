import React, { useState, useRef, useLayoutEffect, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, useLocation } from 'react-router-dom';
import App from '../../App';
import * as dealerService from '../../services/dealerService';
import * as homesService from '../../services/homesService';
import SummerPromoStyles from './SummerPromo.styled';
import Banner from './Banner';
import SaleHomeSlider from '../Shared/SaleHomeSlider/SaleHomeSlider';
import TextContent from '../Shared/TextContent/TextContent';
import EnergySmartZeroCostSavings from '../Shared/EnergySmartZeroCostSavings/EnergySmartZeroCostSavings';
import Video from '../Shared/Video/Video';
import PromoContent from '../Promo/PromoContent';
import PromoDisclaimer from './PromoDisclaimer';
import XLCTA from '../Shared/XLCTA/XLCTA';
import VideoBackgroundImage from '../../images/promo-images/summer-promo/ebuilt-video-banner.webp';
import EBuiltLogo from '../../images/promo-images/summer-promo/ebuilt-logo.svg';
import { formatDealerName } from 'pubweb-smokey/dist/utils/formatters';
import { parseInitialPropsContext } from '../../utils';
import Colors from '../../colors';
import { smoothScroll } from '../Shared/SmokeyTransitional/utils/utils';

const promoIsInactive = true;

const SummerPromo = ({ dealer, homes, homesOnSale, isLoading }) => {
  const [showTy, setShowTy] = useState(false);
  const disclaimerRef = useRef();

  const showThankYou = () => {
    setShowTy(true);
  };

  const isClayton = () => {
    if (dealer?.brandAcronym.toLowerCase() === 'cla') {
      return true;
    }
    return false;
  };

  const handleScroll = () => {
    smoothScroll('#PromoDisclaimer', -90);
  };

  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    if (hash) {
      setTimeout(() => {
        smoothScroll(hash, -90);
      }, 800);
    }
  }, []);

  if (dealer?.websiteTemplateDescription == 'BuilderModel') {
    return <Redirect to="/" />;
  }
  if (dealer?.websiteTemplateDescription === 'NoSpecialOffers') {
    return <Redirect to="/sale-homes" />;
  }
  if (promoIsInactive) {
    return <Redirect to="/special-offers/red-tag-sales-event" />;
  }

  return (
    <App dealer={dealer} homes={homes} isLoading={isLoading}>
      <SummerPromoStyles>
        <Helmet>
          <title>
            Opening Doors Sales Event - Affordable eBuilt Manufactured, Mobile
            Homes | {dealer ? formatDealerName(dealer?.dealerName) : ''}
          </title>
          <meta
            name="description"
            content={`Come visit our Clayton Retailers today to learn more about special offers and promotions available in your area. `}
          />
          <meta
            name="og:description"
            content={`Come visit our Clayton Retailers today to learn more about special offers and promotions available in your area. `}
          />
        </Helmet>
        <Banner
          dealer={dealer}
          homes={homes}
          showThankYouCallBack={showThankYou}
          showTy={showTy}
          isClayton={isClayton}
          bannerHeadline={
            <>
              New homes starting <span className="bold">under $150,000</span>*.
            </>
          }
          bannerDisclaimer={
            <>
              *Sales price does not include required taxes, delivery &
              installation. Sale homes will vary by retailer and state and may
              not be available on all models. For complete pricing and other
              details <a onClick={handleScroll}>click here</a>.
            </>
          }
          formLogo={EBuiltLogo}
          formOfferText={
            'Complete this form to receive information about sale homes and future offers.'
          }
        />
        <TextContent
          smallHeadline={'OPENING DOORS SALES EVENT'}
          largeHeadline={'Welcome to eBuilt®.'}
          content={
            <>
              We’re changing the way our homes are made and strive to make them
              affordable in the process. Introducing high-performing, energy
              efficient eBuilt® homes that can save you up to 50%{' '}
              <span className="superscript">1</span> on annual energy costs. For
              a limited time, you can also save thousands* on these modern
              manufactured homes.
            </>
          }
          links={[
            {
              linkContent: (
                <>
                  <p className="link-text">
                    <span className="bold">1</span> Homes are built to the U.S.
                    DOE Zero Energy Ready Home™ standards.
                  </p>
                  <a
                    href="https://www.energy.gov/sites/default/files/2022-11/ZERH%20Name%20and%20Logo%20Use%20Guidelines_0.pdf"
                    target="_blank"
                  >
                    ZERH Logo Use Guidelines (energy.gov)
                  </a>
                </>
              ),
            },
          ]}
          smallDisclaimer={
            <>
              * Sale homes will vary by retailer and state and may not be
              available on all models. Contact your local retailer for a list of
              eligible homes.
            </>
          }
          backgroundColor={Colors.primary.white.standard}
          largePadding={true}
          isPromo={true}
        />
        <EnergySmartZeroCostSavings
          dealer={dealer}
          isPromoBackgroundImg={true}
          isPromo={true}
        />
        <Video
          gaClass={'ga-seasonal-campaign-video'}
          image={{ source: VideoBackgroundImage }}
          videoID={isClayton() ? '7pk8nc9rud' : 'voyo63ifck'}
          wistiaOptions={'silentAutoPlay=false autoPlay=true'}
          isPromoBackgroundImg={true}
          isPromo={true}
          retailEventLabel={'campaign_video'}
          noSaleHomes={!homesOnSale || homesOnSale.length <= 0}
        />
        {homesOnSale && homesOnSale.length > 0 && (
          <SaleHomeSlider
            sliderId="promoSlider"
            largeHeadline={
              !promoIsInactive ? 'Explore More Ways to Save' : 'Explore Homes'
            }
            saleHomes={homesOnSale}
            buttonText={'View All Sale Homes'}
            buttonURL={'/sale-homes'}
            gaClass={'ga-seasonal-campaign-view-sale-homes'}
            retailEventLabel={'campaign_sale_homes_view_all'}
            dealer={dealer}
            isPromo={true}
          />
        )}
        <PromoContent
          isSeasonalCampaign={true}
          formatDealerName={formatDealerName}
          dealer={dealer}
          homes={homes}
        />
        <div id="PromoDisclaimer" ref={disclaimerRef}>
          <PromoDisclaimer
            disclaimer={
              '*Sales price is for the new home only. Sales price does not include delivery and installation or other costs such as taxes, title fees, insurance premiums, filing or recording fees, land or improvements to the land, optional home features, optional delivery or installation services, wheels and axles, community or homeowner association fees, or any other items not shown on your Sales Agreement, Retailer Closing Agreement, and related documents (your SA/RCA). If you purchase a home, your SA/RCA will show the details of your purchase. Sale homes under the $150,000 sales price will vary by retailer and state and may not be available for all models. See local retailer for list of eligible homes. Available with a purchase agreement signed June 1, 2024 through August 31, 2024. Available only at participating Clayton Family of Brands retailers.'
            }
          />
        </div>
        <XLCTA
          phoneNumber={dealer.phoneNumber}
          gaPhoneClass={'ga-seasonal-campaign-phone-number'}
          customCallText="Call Us Today"
          dealer={dealer}
          homes={homes}
          buttons={[
            {
              buttonText: 'Get More Information',
              buttonURL: '/contact-us',
              gaClass: 'ga-seasonal-campaign-get-more-information',
              openForm: 'requestInfo',
              retailEventLabel: 'footer_dual_cta_request',
            },
            {
              buttonText: 'Schedule a Visit',
              buttonURL: '/contact-us',
              gaClass: 'ga-seasonal-campaign-schedule-a-visit',
              openForm: 'scheduleVisit',
              retailEventLabel: 'footer_dual_cta_schedule',
            },
          ]}
        />
      </SummerPromoStyles>
    </App>
  );
};

SummerPromo.getInitialProps = async (ctx) => {
  return await parseInitialPropsContext(ctx, dealerService, homesService);
};

export default SummerPromo;
