import styled from 'styled-components';
import Colors from 'pubweb-smokey/dist/colors.js';
import * as variables from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';
import Color from '../../../colors';

const DualImageCTAStyles = styled.div`
  .cta-container {
    position: relative;
    width: 100%;
    margin: 0 auto;
    border: 0px solid;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    overflow: hidden;
    width: 100%;
    @media only screen and (min-width: 1025px) {
      min-height: 449px;
    }
    @media only screen and (max-width: 1024px) {
      background-image: none !important;
    }
    .left-pattern {
      position: absolute;
      left: 0;
      top: 0;
      width: 67vw;
      max-width: 680px;
      @media only screen and (max-width: 775px) {
        width: 80vw;
      }
    }
    .right-pattern {
      position: absolute;
      right: 0;
      @media only screen and (min-width: 1024px) {
        top: 0;
      }
      @media only screen and (max-width: 1023px) {
        bottom: 0;
        transform: rotate(90deg);
      }
    }
  }
  .green-background {
    background-color: ${Colors.secondary.kiddiePool.standard};
  }
  .red-tag-btn {
    color: #e41d3a !important;
  }
  .dual-image-cta {
    position: relative;
    max-width: 1020px;
    margin: 0 auto;
    padding: 70px 0 91px;

    @media only screen and (min-width: 1025px) {
      display: flex;
      justify-content: space-between;
      padding-top: 91px;
    }

    .content {
      position: relative;
      max-width: 498px;
      width: 49%;
      z-index: 2;
      min-height: 268px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 1rem;
      background-size: cover;

      picture {
        width: 100%;
        height: 100%;
        display: flex;
        position: absolute;
        img {
          object-fit: cover;
          height: auto;
          width: 100%;
        }
      }

      p,
      div {
        position: relative;
        z-index: 3;
      }

      @media only screen and (max-width: 1024px) {
        justify-content: center;
        align-items: center;
        text-align: center;
        margin: 0 auto;
        max-width: 512px;
        width: 67%;

        &:first-of-type {
          margin-bottom: 26px;
        }
      }

      @media only screen and (max-width: 767px) {
        justify-content: center;
        align-items: center;
        text-align: center;
        margin: 0 auto;
        max-width: 327px;
        width: 87%;
      }
    }
    .small-text {
      text-transform: uppercase;
      color: white;
      font-weight: 700;
      font-size: 12px;
      line-height: 1;
      margin: 0 auto 12px;
      letter-spacing: 0.5px;
      font-family: 'source-sans-pro';
    }
    .large-text {
      color: white;
      line-height: 31px;
      font-size: 26px;
      margin: 0 auto 28px;
      min-height: 62px;
      max-width: 400px;
      @media only screen and (max-width: 1100px) {
        line-height: 1.1;
        margin-bottom: 20px;
      }
    }
    .large-text.promo {
      min-height: auto;
    }
    .btn-wrap {
      display: flex;
      justify-content: center;
    }
    .cta-button {
      background: ${Colors.primary.white.standard};
      color: ${Colors.primary.claytonBlue.standard};
      border-radius: 2px;
      padding: 17px 17px;
      font: 500 14px/100% 'source-sans-pro', sans-serif;
      margin: 9px auto 0;
      letter-spacing: 1px;
      text-decoration: none;
      transition: 0.2s;
      text-transform: uppercase;
      font-weight: 700;
      display: block;
      cursor: pointer;
      &:hover {
        opacity: 0.9;
      }
    }
    .promo-btn {
      background: ${Colors.primary.white.standard};
      color: ${Color.accent.redTagRed.standard};
    }
  }
`;

export default DualImageCTAStyles;
